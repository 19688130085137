/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/uikit@3.23.4/dist/css/uikit.min.css
 * - /npm/instantsearch.css@7.4.5/themes/satellite-min.min.css
 * - /npm/uppy@2.9.3/dist/uppy.min.css
 * - /npm/flatpickr@4.6.11/dist/flatpickr.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
